<template>
  <div data-app>
    <v-card :loading="loadingTree">
      <v-card-title>
        <h3 style="color: #e33354" class="ml-3">{{ cardTitle }}</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <PageModalForm
              :modalData="modalData"
              :permissions="permissions"
              :statuses="statuses"
              :endPoint="endPoint"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></PageModalForm>

            <v-treeview
              open-all
              :items="computedPageCollection"
              item-key="id"
              dense
              item-children="children"
              hoverable
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon
                  v-if="
                    typeof item.translations[appLocale] != 'undefined' &&
                    item.translations[appLocale]
                  "
                  color="green"
                  small
                  >check_circle</v-icon
                >
              </template>

              <template slot="label" slot-scope="{ item }">
                <span @click="handleClickItem(item)" style="cursor: pointer">
                  {{ $helpers.getTranslated(item.translations).name }}
                </span>
              </template>

              <template v-slot:append="{ item }">
                <!-- <v-icon
                  color="primary"
                  small
                  class="mr-2"
                  @click="handleClickItem(item)"
                >
                  mdi-pencil
                </v-icon> -->
                <v-icon
                  color="primary"
                  v-if="permissionCan('delete')"
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-treeview>
          </v-col>
        </v-row>

        <DeleteModalDialog
          :dialogDelete="dialogDelete"
          @closeDelete="handleCloseDelete"
          @deleteItemConfirm="handleDeleteItemConfirm"
        >
        </DeleteModalDialog>

        <!--<v-data-table
          :headers="headers"
          :items="pageCollection"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <PageModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></PageModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="statuses.system">{{
              statuses.pages.page[item.status].value
            }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>-->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import PageModalForm, { initialFormData } from "./PageModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import i18nService from "@/core/services/i18n.service.js";

export const PERMISSION_TO = "pages.page.";

export default {
  name: "Pages",
  components: { PageModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      appLocale: i18nService.getUserActiveLanguage(),
      cardTitle: this.$t("MENU.PAGES"),
      search: "",
      routePath: "/cms/pages/pages/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      /*headers: [
        {
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
        },
        {
          text: this.$t("TABLE.page"),
          value: "name",
        },
        { text: this.$t("TABLE.status"), value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],*/

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      loadingTree: true,
    };
  },

  computed: {
    ...mapGetters(["pageCollection", "statuses"]),

    endPoint() {
      return "pages/admin/" + SiteService.getActiveSiteId() + "/page/";
    },
    computedPageCollection() {
      return this.createDataForTreeselect(this.pageCollection);
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchPage"]),
    fetchModel() {
      return this.fetchPage();
    },
    createDataForTreeselect(layer, page_id = null) {
      const vertex = new Map(),
        others = [];
      layer.forEach((item) => {
        if (item.page_id === page_id) {
          vertex.set(item.id, item);
        } else {
          others.push(item);
        }
      });
      for (const vertexId of vertex.keys()) {
        const children = this.createDataForTreeselect(others, vertexId);
        if (children.length) {
          vertex.get(vertexId).children = children;
        }
      }
      return [...vertex.values()];
    },
  },

  mounted() {
    this.fetchModel().then(() => (this.loadingTree = false));
  },
};
</script>
